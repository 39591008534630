body {
  margin: 0;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

#container {
  /*background-image: url("./background.png");
	background-size: cover;*/
  width: 100vw;
  height: 100vh;
  position: fixed;
}

.bg {
	width: 100vw;
	height: 100vh;
	max-width:100%;
	max-height:100%;
}